<template>
  <v-container fluid class="product-description" v-if="tabs.length > 0">
    <!-- v-tabs per visibilità desktop -->
    <template v-if="!$vuetify.breakpoint.smAndDown">
      <v-container>
        <v-tabs v-model="selectedTab" fixed-tabs>
          <v-tab v-for="(tab, idx) in tabs" :key="idx" :class="tab.cssClass">
            <v-flex :class="idx + 1 < tabs.length ? 'divider' : ''">{{
              tab.title
            }}</v-flex>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item
            v-for="tab in tabs"
            :key="tab.title"
            :class="tab.cssClass"
          >
            <div
              v-for="item in tab.items"
              :key="item.title"
              class="pb-4"
              :class="item.cssClass"
            >
              <h4 class="text-uppercase" v-if="item.title">{{ item.title }}</h4>
              <div class="content" v-for="path in item.paths" :key="path.key">
                <component
                  :is="path.template || 'DefaultTpl'"
                  :content="path.content"
                  :append="path.append"
                />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </template>

    <template v-else>
      <v-expansion-panels
        class="mb-2"
        accordion
        flat
        tile
        :focusable="selectedTab"
      >
        <v-expansion-panel
          v-for="tab in tabs"
          :key="tab.title"
          :class="tab.cssClass"
        >
          <v-expansion-panel-header class="pa-0">
            <h4 v-html="tab.title"></h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-2">
            <div
              v-for="item in tab.items"
              :key="item.title"
              class="pb-4"
              :class="item.cssClass"
            >
              <h4 v-if="item.title">{{ item.title }}</h4>
              <div v-for="path in item.paths" :key="path.key">
                <component
                  :is="path.template || 'DefaultTpl'"
                  :content="path.content"
                  :append="path.append"
                />
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>

<script>
import {
  DefaultTpl,
  AnchorTpl,
  LabelsTpl,
  ItemsMapTpl,
  TableTpl,
  NameAndValueTpl,
  ValueTpl,
  WarningsTpl,
  ListTpl
} from "./templates";
import get from "lodash/get";
import kebabCase from "lodash/kebabCase";
import tabsConfig from "/public/product/descriptionTabsConfig.json";
export default {
  name: "ProductDescription",
  /* eslint-disable vue/no-unused-components */
  components: {
    DefaultTpl,
    AnchorTpl,
    LabelsTpl,
    ItemsMapTpl,
    TableTpl,
    NameAndValueTpl,
    ValueTpl,
    WarningsTpl,
    ListTpl
  },
  data() {
    return {
      selectedTab: null,
      productDescriptionTabs: tabsConfig,
      showMetrologicE: false,
      showMetrologicEDrained: false,
      showMetrologicEPackaging: false,
      showMetrologicEDrainedPackaging: false,
      showMetrologicEIsPackage: false
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productMetadata: {
      type: Object
    },
    ingredientList: {
      type: Object
    }
  },
  computed: {
    tabs() {
      let tabs = [];
      this.productDescriptionTabs.forEach(tabMeta => {
        let items = [];
        tabMeta.items.forEach(parMeta => {
          let paths = [];
          parMeta.paths.forEach(pathMeta => {
            let content = get(this.product, pathMeta.data || pathMeta);
            let append = pathMeta.append;
            // check if content is empty
            if (content && content !== "<p></p>") {
              // check if table has empty cells
              if (pathMeta.template === "TableTpl") {
                let cells = JSON.parse(content).cells;
                console.log(cells);
                let hasContent = cells.find(c => {
                  return c !== null;
                });
                if (!hasContent) {
                  return;
                }
              }
              if (pathMeta.template === "ValueTpl") {
                let value = JSON.parse(content).value;
                if (!value) {
                  return;
                }
              }
              // check if list has no options
              // if (pathMeta.template === "ListTpl") {
              //   try {
              //     let options = JSON.parse(content).options;
              //     if (options.length < 1) {
              //       return;
              //     }
              //   } catch (e) {
              //     return content;
              //   }
              // }
              paths.push({
                key: pathMeta.data || pathMeta,
                content: content,
                template: pathMeta.template,
                append: append
              });
            }
          });
          if (paths.length > 0) {
            items.push({
              title: parMeta.title,
              paths: paths,
              cssClass: get(parMeta, "cssClass", kebabCase(parMeta.title))
            });
          }
        });
        if (items.length > 0) {
          tabs.push({
            title: tabMeta.title,
            items: items,
            cssClass: get(tabMeta, "cssClass", kebabCase(tabMeta.title))
          });
        }
      });
      let descriptionTab = tabs.find(t => {
        return t.title === "Descrizione";
      });
      if (descriptionTab) {
        const estimatedSymbol =
          '<span class="ml-1" style="color: #333333;font-size: 20px;">℮</span>';

        descriptionTab.items.find(d => {
          if (d.title === "Quantità:") {
            var custom_paths = [];
            // If METROLOGIC_E_DESCRIPTION exists, return it, else do the evaluation for metrologic_e and NET_WEIGHT

            d.paths.find(e => {
              if (
                e.key === "metaData.product_description.METROLOGIC_E" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicE = true;
                return;
              }
            });
            // d.paths.find(e => {
            //   if (
            //     e.key ===
            //       "metaData.product_description..METROLOGIC_E_IS_PACKAGE" &&
            //     (e.content.toLowerCase() === "yes" ||
            //       e.content.toLowerCase() === "si" ||
            //       e.content.toLowerCase() === "sì")
            //   ) {
            //     this.showMetrologicEIsPackage = true;
            //     return;
            //   }
            // });
            d.paths.find(e => {
              if (
                e.key ===
                  "metaData.product_description.METROLOGIC_E_IS_PACKAGE" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicEIsPackage = true;
                return;
              }
            });

            d.paths.find(e => {
              if (
                e.key ===
                  "metaData.product_description.METROLOGIC_E_PACKAGING" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicEPackaging = true;
                return;
              }
            });

            d.paths = d.paths.filter(function(el) {
              return el.key !== "metaData.product_description.METROLOGIC_E";
            });

            d.paths = d.paths.filter(function(el) {
              return (
                el.key !== "metaData.product_description.METROLOGIC_E_PACKAGING"
              );
            });

            d.paths.find(p => {
              if (p.key === "metaData.product_description.AVARAGE_WEIGHT") {
                custom_paths.push(p);
                d.paths = custom_paths;
                return;
              }
            });
            if (
              this.showMetrologicEPackaging ||
              (this.showMetrologicE && this.showMetrologicEIsPackage)
            ) {
              d.paths.find(p => {
                const index = p.content.lastIndexOf(")");
                if (index !== -1) {
                  p.content =
                    p.content.slice(0, index) +
                    estimatedSymbol +
                    p.content.slice(index);
                }
                console.log("p.content", p.content);
              });
            } else if (this.showMetrologicE) {
              d.paths.find(p => {
                p.content = `${p.content}${estimatedSymbol}`;
              });
            }
          }

          if (d.title === "Peso sgocciolato:") {
            var custom_paths_drained = [];
            d.paths.find(e => {
              if (
                e.key === "metaData.product_description.METROLOGIC_E_DRAINED" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicEDrained = true;
                return;
              }
            });
            d.paths.find(e => {
              if (
                e.key ===
                  "metaData.product_description.METROLOGIC_E_IS_PACKAGE" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicEIsPackage = true;
                return;
              }
            });
            d.paths.find(e => {
              if (
                e.key ===
                  "metaData.product_description.METROLOGIC_E_DRAINED_PACKAGING" &&
                (e.content.toLowerCase() === "yes" ||
                  e.content.toLowerCase() === "si" ||
                  e.content.toLowerCase() === "sì")
              ) {
                this.showMetrologicEDrainedPackaging = true;
                return;
              }
            });

            d.paths = d.paths.filter(function(el) {
              return (
                el.key !== "metaData.product_description.METROLOGIC_E_DRAINED"
              );
            });

            d.paths = d.paths.filter(function(el) {
              return (
                el.key !==
                "metaData.product_description.METROLOGIC_E_DRAINED_PACKAGING"
              );
            });

            d.paths.find(p => {
              if (p.key === "metaData.product_description.NET_DRAINED_WEIGHT") {
                custom_paths_drained.push(p);
                d.paths = custom_paths_drained;
                return;
              }
            });
            if (
              this.showMetrologicEDrainedPackaging ||
              (this.showMetrologicEDrained && this.showMetrologicEIsPackage)
            ) {
              d.paths.find(p => {
                const index = p.content.lastIndexOf(")");

                if (index !== -1) {
                  p.content =
                    p.content.slice(0, index) +
                    estimatedSymbol +
                    p.content.slice(index);
                }
              });
            } else if (
              this.showMetrologicEDrained &&
              !this.showMetrologicEDrainedPackaging
            ) {
              d.paths.find(p => {
                p.content = p.content + estimatedSymbol;
              });
            }
          }
        });
      }

      // additional text at Ingredients tab end
      let ingredientsTab = tabs.find(t => {
        return t.title === "Ingredienti";
      });
      if (ingredientsTab) {
        ingredientsTab.items.push({
          paths: [
            { content: this.$t("productDescription.ingredientsAdditionalInfo") }
          ]
        });
      }
      return tabs;
    }
  }
};
</script>
